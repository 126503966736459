<template>
  <div class="mycases create-case">
    <div class="pageheader">
      <h2>{{ $t("New Case") }}</h2>
    </div>
    <div class="card">
      <div class="card-body">
        <validation-observer ref="simpleRules">
          <section id="2d-to-3d-cards">
            <div
              v-if="isSelectedJaw === 'lower'"
              class="d-flex align-items-center mb-1 gap-2"
            >
              <div class="d-flex align-items-center gap-1">
                <label> {{ $t("Select Size") }}: </label>
                <input
                  type="range"
                  min="0.1"
                  max="3"
                  step="0.1"
                  v-model="lowerSize"
                />
              </div>
              <button class="btn btn-primary" @click="rotateBtn('lower')">
                <span>{{ $t("Rotate") }}</span>
              </button>
              <button class="btn btn-primary" @click="translateBtn('lower')">
                <span>{{ $t("Translate") }}</span>
              </button>
              <div class="d-flex align-items-center gap-1">
                <label for="sensitivityInput">
                  {{ $t("Control Sensitivity") }}:
                </label>
                <input
                  type="range"
                  min="0.1"
                  max="5"
                  step="0.1"
                  v-model="sensitivity"
                />
              </div>
              <div class="checkbox-group">
                <input
                  type="radio"
                  v-model="selectedControl"
                  name="control-selection"
                  value="orbit"
                  class="checkbox-input"
                  @change="switchControls"
                  id="orbit"
                />
                <label for="orbit" class="checkbox-label">
                  {{ $t("Orbit") }}
                </label>
              </div>
              <div class="checkbox-group">
                <input
                  type="radio"
                  class="checkbox-input"
                  id="trackball"
                  v-model="selectedControl"
                  name="control-selection"
                  value="trackball"
                  @change="switchControls"
                />
                <label for="trackball" class="checkbox-label">
                  {{ $t("Trackball") }}
                </label>
              </div>
              <div class="checkbox-group">
                <input
                  type="radio"
                  class="checkbox-input"
                  id="fly"
                  v-model="selectedControl"
                  name="control-selection"
                  value="fly"
                  @change="switchControls"
                />
                <label for="fly" class="checkbox-label">
                  {{ $t("Fly") }}
                </label>
              </div>
            </div>
            <div
              v-if="isSelectedJaw === 'upper'"
              class="d-flex align-items-center mb-1 gap-2"
            >
              <div class="d-flex align-items-center gap-1">
                <label> {{ $t("Select Size") }}: </label>
                <input
                  type="range"
                  min="0.1"
                  max="3"
                  step="0.1"
                  v-model="upperSize"
                />
              </div>
              <button class="btn btn-primary" @click="rotateBtn('upper')">
                <span>{{ $t("Rotate") }}</span>
              </button>
              <button class="btn btn-primary" @click="translateBtn('upper')">
                <span>{{ $t("Translate") }}</span>
              </button>

              <div class="d-flex align-items-center gap-1">
                <label for="sensitivityInputLower">
                  {{ $t("Control Sensitivity") }}:
                </label>
                <input
                  type="range"
                  min="0.1"
                  max="5"
                  step="0.1"
                  v-model="sensitivityUpper"
                />
              </div>
              <div class="checkbox-group">
                <input
                  type="radio"
                  v-model="selectedControlUpper"
                  name="control-selection-uppers"
                  value="orbit"
                  class="checkbox-input"
                  @change="switchControlsUpper"
                  id="orbit"
                />
                <label for="orbit" class="checkbox-label">
                  {{ $t("Orbit") }}
                </label>
              </div>
              <div class="checkbox-group">
                <input
                  type="radio"
                  class="checkbox-input"
                  id="trackball"
                  v-model="selectedControlUpper"
                  name="control-selection-upper"
                  value="trackball"
                  @change="switchControlsUpper"
                />
                <label for="trackball" class="checkbox-label">
                  {{ $t("Trackball") }}
                </label>
              </div>
              <div class="checkbox-group">
                <input
                  type="radio"
                  class="checkbox-input"
                  id="fly"
                  v-model="selectedControlUpper"
                  name="control-selection-upper"
                  value="fly"
                  @change="switchControlsUpper"
                />
                <label for="fly" class="checkbox-label">
                  {{ $t("Fly") }}
                </label>
              </div>
            </div>
            <b-row>
              <b-col md="7" xl="7">
                <div class="d-upload-dropzone cursor-pointer">
                  <!--Lower Jaw Canvas-->
                  <div
                    class="lower-card"
                    :class="{
                      active: isSelectedJaw === 'lower',
                    }"
                    ref="card"
                  >
                    <canvas
                      id="canvas"
                      class="upper-canvas"
                      v-show="isSelectedJaw === 'lower'"
                    ></canvas>
                  </div>
                  <!--Upper Jaw Canvas-->
                  <div class="upper-card" ref="cardUpper">
                    <canvas
                      id="canvasUpper"
                      class="upper-canvas"
                      v-show="isSelectedJaw === 'upper'"
                    ></canvas>
                  </div>
                  <!--When NO Jaws-->
                  <!-- <div
                                        v-if="!upperJawFile && !lowerJawFile"
                                        class="dropzone-content"
                                    >
                                        <div class="icon">
                                            <ElementIcon
                                                v-bind:icon="'uploadIcon'"
                                            />
                                        </div>
                                        <div>
                                            <h3>
                                                3D Intraoral-Scan (STL or OBJ)
                                            </h3>
                                        </div>
                                    </div> -->

                  <div
                    class="dropzone-check"
                    :class="isSelectedJaw ? 'active' : ''"
                  >
                    <div class="checkbox-group">
                      <input
                        type="radio"
                        class="checkbox-input"
                        id="upper"
                        v-model="isSelectedJaw"
                        name="jaw-selection"
                        value="upper"
                        :checked="isSelectedJaw === 'upper'"
                      />
                      <label for="upper" class="checkbox-label">
                        {{ $t("Show Upper Jaw") }}
                      </label>
                    </div>
                    <div class="checkbox-group">
                      <input
                        type="radio"
                        class="checkbox-input"
                        id="lower"
                        v-model="isSelectedJaw"
                        name="jaw-selection"
                        value="lower"
                        :checked="isSelectedJaw === 'lower'"
                      />
                      <label for="lower" class="checkbox-label">
                        {{ $t("Show Lower Jaw") }}
                      </label>
                    </div>
                  </div>
                </div>
              </b-col>

              <b-col md="5" xl="5">
                <div class="mb-2">
                  <h4>{{ $t("Upper Jaw") }}</h4>
                  <div class="d-flex align-items-center gap-2">
                    <div
                      class="custom-dropdzone"
                      @dragover.prevent="onDragOver"
                      @dragleave="onDragLeave"
                      @drop.prevent="onDrop($event, 'upper')"
                      :class="{
                        'is-dragover': isDragOver,
                      }"
                    >
                      <div v-if="!upperJawFile" class="dropzone-content">
                        <div class="icon">
                          <ElementIcon v-bind:icon="'uploadIcon'" />
                        </div>
                        <div>
                          <h3>
                            {{ $t("Select a file or drag and drop here") }}
                          </h3>
                          <p>.obj, .stl, file size no more than 50MB</p>
                        </div>
                      </div>
                      <div
                        v-if="upperJawFile"
                        class="uploaded-file-name d-flex align-items-center"
                      >
                        <p class="mr-2">
                          {{ $t("Uploaded Upper Jaw") }}:
                          {{ upperJawFile.name }}
                        </p>
                        <feather-icon
                          class="cursor-pointer"
                          @click="deleteUpperJawFile()"
                          size="16"
                          icon="TrashIcon"
                        />
                      </div>
                    </div>
                    <input
                      type="file"
                      ref="fileUpperJawInput"
                      @change="previewJaw('upper', $event)"
                      accept=".obj, .stl"
                      style="display: none"
                    />
                    <button
                      class="btn btn-secondary"
                      @click="openUpperJawFilePicker"
                    >
                      <ElementIcon v-bind:icon="'folderIcon'" />
                      <span>{{ $t("Browse") }}</span>
                    </button>
                  </div>
                  <p v-if="upperJawFile">
                    {{ $t("The cost for upper jaw is:") }}
                    {{ costsPerUpperJaw }}
                    {{ $t("credits") }}
                  </p>
                </div>
                <h2 class="mb-2">{{ $t("AND") }} / {{ $t("OR") }}</h2>
                <div class="mb-2">
                  <h4>{{ $t("Lower Jaw") }}</h4>
                  <div class="d-flex align-items-center gap-2">
                    <div
                      class="custom-dropdzone"
                      @dragover.prevent="onDragOver"
                      @dragleave="onDragLeave"
                      @drop.prevent="onDrop($event, 'lower')"
                      :class="{
                        'is-dragover': isDragOver,
                      }"
                    >
                      <div v-if="!lowerJawFile" class="dropzone-content">
                        <div class="icon">
                          <ElementIcon v-bind:icon="'uploadIcon'" />
                        </div>
                        <div>
                          <h3>
                            {{ $t("Select a file or drag and drop here") }}
                          </h3>
                          <p>.obj, .stl, file size no more than 50MB</p>
                        </div>
                      </div>
                      <div
                        v-if="lowerJawFile"
                        class="uploaded-file-name d-flex align-items-center"
                      >
                        <p class="mr-2">
                          {{ $t("Uploaded Lower Jaw") }}:
                          {{ lowerJawFile.name }}
                        </p>
                        <feather-icon
                          class="cursor-pointer"
                          @click="deleteLowerJawFile"
                          size="16"
                          icon="TrashIcon"
                        />
                      </div>
                    </div>
                    <input
                      type="file"
                      ref="fileLowerJawInput"
                      @change="previewJaw('lower', $event)"
                      accept=".obj, .stl"
                      style="display: none"
                    />
                    <button
                      class="btn btn-secondary"
                      @click="openLowerJawFilePicker"
                    >
                      <ElementIcon v-bind:icon="'folderIcon'" />
                      <span>{{ $t("Browse") }}</span>
                    </button>
                  </div>

                  <p v-if="lowerJawFile">
                    {{ $t("The cost for lower jaw is:") }}
                    {{ costsPerLowerJaw }}
                    {{ $t("credits") }}
                  </p>
                </div>
                <b-form-group :label="$t('Case ID*')" label-for="caseNameInput">
                  <validation-provider
                    #default="{ errors }"
                    name="Case ID"
                    rules="required"
                  >
                    <b-form-input
                      id="caseNameInput"
                      :state="
                        isErrorMessage && errors.length > 0 ? false : null
                      "
                      v-model="caseName"
                    />
                    <small v-if="isErrorMessage" class="text-danger">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group :label="$t('Comment')" label-for="detailsInput">
                  <b-form-textarea id="detailsInput" v-model="caseDetails" />
                </b-form-group>

                <input
                  type="checkbox"
                  class="checkbox-input"
                  v-model="termsAndConditions"
                />
                <span>
                  {{
                    $t(
                      "I aligned the uploaded model to the provided template. I know that bad alignment will result in bad results from the AI"
                    )
                  }}
                </span>
                <!-- Remaining fields... -->
                <p v-if="upperJawFile || lowerJawFile" class="mt-2">
                  {{ $t("The sum of credits is") }}:
                  {{
                    upperJawFile && lowerJawFile
                      ? parseFloat(costsPerUpperJaw) +
                        parseFloat(costsPerLowerJaw)
                      : upperJawFile
                      ? parseFloat(costsPerUpperJaw)
                      : parseFloat(costsPerLowerJaw)
                  }}
                  {{ $t("credits") }}
                </p>
              </b-col>
            </b-row>
          </section>
        </validation-observer>
      </div>
    </div>
    <b-modal
      id="edit-tag-modal"
      v-model="showModal"
      title="Not enough credits"
      centered
      size="md"
      hide-footer
    >
      {{ $t("You do not have enough credits to upload the case") }}
      <div class="d-flex align-items-center justify-content-end mt-2">
        <b-button @click="showModal = false" variant="primary">{{
          $t("Ok")
        }}</b-button>
      </div>
    </b-modal>
    <CookieButton>
      <template #cookieBtns>
        <button @click="reloadPage" class="btn btn-secondary mr-2">
          <ElementIcon v-bind:icon="'xIcon'" /><span>{{ $t("Cancel") }}</span>
        </button>
        <button
          :disabled="isDisabled"
          class="btn btn-primary"
          @click="uploadData2DTo3D"
        >
          <ElementIcon v-bind:icon="'sendIcon'" /><span>{{ $t("Send") }}</span>
        </button>
      </template>
    </CookieButton>
  </div>
</template>

<script>
import CookieButton from "@/components/elements/CookieButton.vue";
import {
  BRow,
  BCol,
  BFormInput,
  BFormRadio,
  BCard,
  BCardText,
  BCardTitle,
  BImg,
  BFormFile,
  BFormGroup,
  BButton,
} from "bootstrap-vue";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls.js";
import { FlyControls } from "three/examples/jsm/controls/FlyControls.js";
import { TransformControls } from "three/examples/jsm/controls/TransformControls.js";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader.js";
import { STLExporter } from "three/examples/jsm/exporters/STLExporter.js";
import NProgress from "nprogress";
import FileService from "@/services/file.service";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import TextInput from "@/components/TextInput.vue";
import TextareaInput from "@/components/TextareaInput.vue";
import ElementIcon from "@/components/elements/Icon.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    BRow,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardTitle,
    BImg,
    BFormFile,
    BFormGroup,
    BButton,
    TextInput,
    TextareaInput,
    ElementIcon,
    CookieButton,
  },
  computed: {
    ...mapGetters(["showLoader"]),
  },
  watch: {
    upperSize: {
      handler(val) {
        if (this.upperGroundTruthModel) {
          this.upperGroundTruthModel.scale.setScalar(val);
        }
      },
      immediate: true,
    },
    lowerSize: {
      handler(val) {
        if (this.lowerGroundTruthModel) {
          this.lowerGroundTruthModel.scale.setScalar(val);
        }
      },
      immediate: true,
    },
    sensitivity(val) {
      let sensitivity = parseFloat(val);
      this.updateControlsSensitivity(
        {
          orbitControls: this.orbitControls,
          trackballControls: this.trackballControls,
          flyControls: this.flyControls,
        },
        sensitivity
      );
    },
    sensitivityUpper(val) {
      let sensitivity = parseFloat(val);

      this.updateControlsSensitivity(
        {
          orbitControls: this.orbitControlsUpper,
          trackballControls: this.trackballControlsUpper,
          flyControls: this.flyControlsUpper,
        },
        sensitivity
      );
    },
    termsAndConditions(val) {
      if (val == true) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
  },
  data() {
    return {
      casesLeft: 0,
      selectedControl: "trackball",
      upperJawFile: null,
      lowerJawFileInfo: null,
      lowerJawFileRotation: { orig: {}, reg: {} },
      opgFile: null,
      upperJawFileInfo: null,
      upperJawFileRotation: { orig: {}, reg: {} },
      lowerJawFileTranslation: { orig: {}, reg: {} },
      upperJawFileTranslation: { orig: {}, reg: {} },
      lowerJawFileMatrixWorld: { orig: [], reg: [] },
      upperJawFileMatrixWorld: { orig: [], reg: [] },
      lowerJawFileQuaternion: { orig: {}, reg: {} },
      upperJawFileQuaternion: { orig: {}, reg: {} },
      lowerJawFileScale: { orig: {}, reg: {} },
      upperJawFileScale: { orig: {}, reg: {} },
      lowerJawFile: null,
      opgFileInfo: null,
      opgFileUrl: null,
      loadingDone: false,
      caseName: "",
      caseDetails: "",
      isDragOver: false,
      isErrorMessage: false,
      isDisabled: true,
      termsAndConditions: false,
      scene: null,
      camera: null,
      renderer: null,
      sensitivity: 2,
      controls: null, // Active camera controls
      orbitControls: null,
      trackballControls: null,
      flyControls: null,
      userModel: null,
      groundTruthModel: null,
      userGroup: null,
      transformControl: null,
      clock: null,
      sceneUpper: null,
      cameraUpper: null,
      rendererUpper: null,
      sensitivityUpper: 1,
      controlsUpper: null, // Active camera controls
      orbitControlsUpper: null,
      trackballControlsUpper: null,
      flyControlsUpper: null,
      userModelUpper: null,
      groundTruthModelUpper: null,
      userGroupUpper: null,
      transformControlUpper: null,
      clockUpper: null,
      selectedControlUpper: "trackball",
      isSelectedJaw: "",
      costsPerLowerJaw: 0,
      costsPerUpperJaw: 0,
      credits: 0,
      showModal: false,
      sumCredits: 0,
      upperGroundTruthModel: null,
      lowerGroundTruthModel: null,
      upperSize: 1,
      lowerSize: 1,
    };
  },

  async mounted() {
    this.$store.commit("showLoader", true);
    const creditResponse = await this.$store.dispatch("customers/getCredits");
    try {
      this.credits = creditResponse?.data?.credits ?? 0;
      this.clock = new THREE.Clock();
      this.clockUpper = new THREE.Clock();
      this.init();

      window.previewCanvas = this;

      this.animate();
      this.initUpper();
      this.animateUpper();
      window.addEventListener("resize", this.onWindowResize);
      window.addEventListener("resize", this.onWindowResizeUpper);
      window.addEventListener("keydown", this.handleKeyDown);
      window.addEventListener("keyup", this.handleKeyUp);
      await this.loadData();
      this.$store.commit("showLoader", false);
    } catch (e) {
      console.error(e);
      this.$store.commit("showLoader", false);
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
    window.removeEventListener("resize", this.onWindowResizeUpper);
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("keyup", this.handleKeyUp);
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
    async loadData() {
      const response = await this.$store.dispatch("caseLists/getCreditCosts");
      this.costsPerLowerJaw = response?.data?.data?.costsPerLowerJaw ?? 0;
      this.costsPerUpperJaw = response?.data?.data?.costsPerUpperJaw ?? 0;
      const upperExtension = response?.data?.data?.upperExtension ?? "stl";
      const lowerExtension = response?.data?.data?.lowerExtension ?? "stl";
      const upperId = response?.data?.data?.upperJawTemplate ?? "";
      const lowerId = response?.data?.data?.lowerJawTemplate ?? "";
      let upperJawFile, lowerJawFile;
      if (upperId !== "") {
        upperJawFile = (await FileService.getFileById(upperId)) ?? null;
      }
      if (lowerId !== "") {
        lowerJawFile = (await FileService.getFileById(lowerId)) ?? null;
      }
      if (upperJawFile) {
        this.isSelectedJaw = "upper";
        await this.previewGroundTruthModel(
          upperJawFile,
          upperExtension,
          this.userGroupUpper,
          "upperGroundTruthModel"
        );
      } else {
        this.isSelectedJaw = "lower";
      }
      if (lowerJawFile) {
        await this.previewGroundTruthModel(
          lowerJawFile,
          lowerExtension,
          this.userGroup,
          "lowerGroundTruthModel"
        );
      }
    },
    async previewGroundTruthModel(jawFile, extension, group, modelProperty) {
      let fileContent = jawFile.data;
      if (!fileContent) return;

      if (!extension || extension === "unknown") {
        console.log("Unsupported file format");
        return;
      }

      // Remove previous model if exists
      if (this[modelProperty]) {
        group.remove(this[modelProperty]);
      }

      let geometry;

      try {
        switch (extension) {
          case "stl":
            {
              let loader = new STLLoader();
              try {
                const arrayBuffer = await fileContent.arrayBuffer();
                geometry = loader.parse(arrayBuffer);
                this[modelProperty] = new THREE.Mesh(
                  geometry,
                  new THREE.MeshNormalMaterial({
                    transparent: true,
                    opacity: 0.5,
                    side: THREE.DoubleSide,
                  })
                );
                group.add(this[modelProperty]);
              } catch (error) {
                console.error("Error parsing STL geometry:", error);
              }
            }
            break;

          case "obj":
            {
              let loader = new OBJLoader();
              try {
                const objText = await fileContent.text();
                this[modelProperty] = loader.parse(objText);
                this.centerObject(this[modelProperty]);
                group.add(this[modelProperty]);
              } catch (error) {
                console.error("Error parsing OBJ model:", error);
              }
            }
            break;

          case "ply":
            {
              let loader = new PLYLoader();
              try {
                const arrayBuffer = await fileContent.arrayBuffer();
                geometry = loader.parse(arrayBuffer);
                this[modelProperty] = new THREE.Mesh(
                  geometry,
                  new THREE.MeshNormalMaterial({
                    transparent: true,
                    opacity: 0.5,
                    side: THREE.DoubleSide,
                  })
                );
                group.add(this[modelProperty]);
              } catch (error) {
                console.error("Error parsing PLY geometry:", error);
              }
            }
            break;

          default:
            console.log("Unsupported file format: " + extension);
            return;
        }
      } catch (error) {
        console.error(`Error parsing ${extension.toUpperCase()} model:`, error);
      }
    },
    init() {
      // Scene and Camera
      this.scene = new THREE.Scene();
      const card = this.$refs.card;

      const cardWidth = card.clientWidth;
      const cardHeight = card.clientHeight;

      this.camera = new THREE.PerspectiveCamera(
        60,
        cardWidth / cardHeight, // Use card dimensions for aspect ratio
        0.1,
        1000
      );
      this.camera.position.set(0, 0, 100);

      // Renderer
      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        canvas: document.getElementById("canvas"),
      });
      this.renderer.setSize(cardWidth, cardHeight);

      // Controls
      this.orbitControls = new OrbitControls(
        this.camera,
        this.renderer.domElement
      );
      this.trackballControls = new TrackballControls(
        this.camera,
        this.renderer.domElement
      );
      this.flyControls = new FlyControls(this.camera, this.renderer.domElement);
      this.flyControls.movementSpeed = 10;
      this.flyControls.rollSpeed = Math.PI / 24;
      this.flyControls.dragToLook = true;
      this.flyControls.autoForward = false;
      this.flyControls.enabled = false; // Initially disabled

      this.trackballControls.screen.height = cardHeight;
      this.trackballControls.screen.width = cardWidth;

      // Set TrackballControls as default
      this.controls = this.trackballControls;
      this.trackballControls.enabled = true;
      this.trackballControls.rotateSpeed = 2; // Increased from default 1 to 2

      // Similarly, adjust other control sensitivities if needed
      this.updateControlsSensitivity(
        {
          orbitControls: this.orbitControls,
          trackballControls: this.trackballControls,
          flyControls: this.flyControls,
        },
        2
      );

      // Light
      const color = 0xffffff;
      const intensity = 1;
      const light = new THREE.AmbientLight(color, intensity);
      this.scene.add(light);

      // User Model Group
      this.userGroup = new THREE.Group();
      this.scene.add(this.userGroup);

      this.switchControls();
    },
    onWindowResize() {
      const card = this.$refs.card;

      const cardWidth = card.clientWidth;
      const cardHeight = card.clientHeight;

      // Update aspect ratio and camera projection
      this.camera.aspect = cardWidth / cardHeight;
      this.camera.updateProjectionMatrix();

      // Update the renderer size based on the card size
      this.renderer.setSize(cardWidth, cardHeight);
    },
    initUpper() {
      // Scene and Camera
      this.sceneUpper = new THREE.Scene();

      const cardUpper = this.$refs.cardUpper; // Reference the card container for upper model

      const cardWidthUpper = cardUpper.clientWidth;
      const cardHeightUpper = cardUpper.clientHeight;

      this.cameraUpper = new THREE.PerspectiveCamera(
        60,
        cardWidthUpper / cardHeightUpper, // Set aspect ratio based on card dimensions
        0.1,
        1000
      );
      this.cameraUpper.position.set(0, 0, -100);

      // Renderer
      this.rendererUpper = new THREE.WebGLRenderer({
        antialias: true,
        canvas: document.getElementById("canvasUpper"),
      });
      this.rendererUpper.setSize(cardWidthUpper, cardHeightUpper); // Set renderer size based on card size

      // Controls
      this.orbitControlsUpper = new OrbitControls(
        this.cameraUpper,
        this.rendererUpper.domElement
      );
      this.trackballControlsUpper = new TrackballControls(
        this.cameraUpper,
        this.rendererUpper.domElement
      );
      this.flyControlsUpper = new FlyControls(
        this.cameraUpper,
        this.rendererUpper.domElement
      );
      this.flyControlsUpper.movementSpeed = 10;
      this.flyControlsUpper.rollSpeed = Math.PI / 24;
      this.flyControlsUpper.dragToLook = true;
      this.flyControlsUpper.autoForward = false;
      this.flyControlsUpper.enabled = false; // Initially disabled

      this.trackballControlsUpper.screen.height = cardWidthUpper;
      this.trackballControlsUpper.screen.width = cardHeightUpper;

      // Set TrackballControls as default
      this.controlsUpper = this.trackballControlsUpper;
      this.trackballControlsUpper.enabled = true;
      this.trackballControlsUpper.rotateSpeed = 2; // Increased from default 1 to 2

      this.updateControlsSensitivity(
        {
          orbitControls: this.orbitControlsUpper,
          trackballControls: this.trackballControlsUpper,
          flyControls: this.flyControlsUpper,
        },
        2
      );

      // Light
      let light = new THREE.HemisphereLight();
      light.position.set(0, 0, 100).normalize();
      this.sceneUpper.add(light);

      // User Model Group
      this.userGroupUpper = new THREE.Group();

      //this.userGroupUpper.rotation.y = Math.PI;

      this.sceneUpper.add(this.userGroupUpper);

      this.switchControlsUpper();

      // Handle resize events for upper model
      window.addEventListener("resize", this.onWindowResizeUpper);
    },
    onWindowResizeUpper() {
      const cardUpper = this.$refs.cardUpper;

      const cardWidthUpper = cardUpper.clientWidth;
      const cardHeightUpper = cardUpper.clientHeight;

      // Update aspect ratio and renderer size for the upper model
      this.cameraUpper.aspect = cardWidthUpper / cardHeightUpper;
      this.cameraUpper.updateProjectionMatrix();
      this.rendererUpper.setSize(cardWidthUpper, cardHeightUpper);
    },

    animate() {
      requestAnimationFrame(this.animate);
      let delta = this.clock.getDelta();

      if (this.controls === this.flyControls) {
        this.flyControls.update(delta);
      } else {
        this.controls.update();
      }

      this.render();
    },
    animateUpper() {
      requestAnimationFrame(this.animateUpper);
      let delta = this.clockUpper.getDelta();

      if (this.controlsUpper === this.flyControlsUpper) {
        this.flyControlsUpper.update(delta);
      } else {
        this.controlsUpper.update();
      }

      this.renderUpper();
    },
    render() {
      this.renderer.render(this.scene, this.camera);
    },
    renderUpper() {
      this.rendererUpper.render(this.sceneUpper, this.cameraUpper);
    },

    rotateBtn(jawType) {
      if (jawType === "upper") {
        if (this.transformControlUpper)
          this.transformControlUpper.setMode("rotate");
      } else {
        if (this.transformControl) this.transformControl.setMode("rotate");
      }
    },
    translateBtn(jawType) {
      if (jawType === "upper") {
        if (this.transformControlUpper)
          this.transformControlUpper.setMode("translate");
      } else {
        if (this.transformControl) this.transformControl.setMode("translate");
      }
    },
    updateControlsSensitivity(controls, sensitivity) {
      // OrbitControls Sensitivity
      controls.orbitControls.rotateSpeed = sensitivity;
      controls.orbitControls.zoomSpeed = sensitivity;
      controls.orbitControls.panSpeed = sensitivity / 2;

      // TrackballControls Sensitivity
      controls.trackballControls.rotateSpeed = sensitivity;
      controls.trackballControls.zoomSpeed = sensitivity;
      controls.trackballControls.panSpeed = sensitivity / 2;

      // FlyControls Sensitivity
      controls.flyControls.movementSpeed = sensitivity * 10;
      controls.flyControls.rollSpeed = (sensitivity * Math.PI) / 24;
    },

    switchControls() {
      // Disable all controls
      this.orbitControls.enabled = false;
      this.trackballControls.enabled = false;
      this.flyControls.enabled = false;

      let currentControl = this.selectedControl;
      switch (currentControl) {
        case "orbit":
          this.controls = this.orbitControls;
          this.orbitControls.enabled = true;
          break;
        case "trackball":
          this.controls = this.trackballControls;
          this.trackballControls.enabled = true;
          break;
        case "fly":
          this.controls = this.flyControls;
          this.flyControls.enabled = true;
          break;
      }
    },
    switchControlsUpper() {
      // Disable all controls
      this.orbitControlsUpper.enabled = false;
      this.trackballControlsUpper.enabled = false;
      this.flyControlsUpper.enabled = false;

      let currentControl = this.selectedControlUpper;
      switch (currentControl) {
        case "orbit":
          this.controlsUpper = this.orbitControlsUpper;
          this.orbitControlsUpper.enabled = true;
          break;
        case "trackball":
          this.controlsUpper = this.trackballControlsUpper;
          this.trackballControlsUpper.enabled = true;
          break;
        case "fly":
          this.controlsUpper = this.flyControlsUpper;
          this.flyControlsUpper.enabled = true;
          break;
      }
    },

    uploadProgress: function (load_status, load_session) {
      this.loadingDone = false;
      var sumLoading = 0;
      var allToLoad = 0;
      for (var i = 0; i < load_status.length; i++) {
        if (
          typeof load_status[i] === "undefined" ||
          typeof load_status[i]["total"] === "undefined"
        ) {
          continue;
        }
        allToLoad += load_status[i]["total"];
        sumLoading += load_status[i]["loaded"];
      }

      var percentage = sumLoading / allToLoad;
      if (percentage > 0.8) {
        NProgress.set(0.6);
        setTimeout(this.keepProgressbarBusy.bind(this), 1100);
      } else {
        NProgress.set(percentage);
      }
    },

    keepProgressbarBusy: function () {
      if (this.loadingDone == false) {
        NProgress.inc(0.1);
        setTimeout(this.keepProgressbarBusy.bind(this), 300);
      } else {
        NProgress.done();
      }
    },


    convertASCIIToBinarySTL: function(asciiData) {
      // Split the ASCII data into lines
      const lines = asciiData.split('\n');
      const triangles = [];

      let normal = null;
      let vertices = [];

      // Process each line to extract normals and vertices
      for (let line of lines) {
        line = line.trim();
        if (line.startsWith('facet normal')) {
          // Extract the normal vector
          const normalData = line.match(/facet normal\s+([^\s]+)\s+([^\s]+)\s+([^\s]+)/);
          if (normalData) {
            normal = [
              parseFloat(normalData[1]),
              parseFloat(normalData[2]),
              parseFloat(normalData[3]),
            ];
          }
          vertices = [];
        } else if (line.startsWith('vertex')) {
          // Extract vertex coordinates
          const vertexData = line.match(/vertex\s+([^\s]+)\s+([^\s]+)\s+([^\s]+)/);
          if (vertexData) {
            const vertex = [
              parseFloat(vertexData[1]),
              parseFloat(vertexData[2]),
              parseFloat(vertexData[3]),
            ];
            vertices.push(vertex);
          }
        } else if (line.startsWith('endfacet')) {
          // Store the triangle data
          if (normal && vertices.length === 3) {
            triangles.push({
              normal: normal,
              vertices: vertices,
            });
          }
          normal = null;
          vertices = [];
        }
      }

      if (triangles.length === 0) {
        throw new Error('No triangles found in the STL file.');
      }

      // Calculate total size: 80-byte header + 4-byte count + 50 bytes per triangle
      const triangleCount = triangles.length;
      const totalSize = 80 + 4 + triangleCount * 50;

      // Create an ArrayBuffer and DataView for binary data
      const buffer = new ArrayBuffer(totalSize);
      const dataView = new DataView(buffer);

      // Write the 80-byte header (empty or you can put some info)
      let offset = 0;
      for (let i = 0; i < 80; i++) {
        dataView.setUint8(offset++, 0);
      }

      // Write the number of triangles (4 bytes, little-endian)
      dataView.setUint32(offset, triangleCount, true);
      offset += 4;

      // Write each triangle's data
      for (const triangle of triangles) {
        // Write normal vector (3 floats)
        for (const n of triangle.normal) {
          dataView.setFloat32(offset, n, true);
          offset += 4;
        }
        // Write vertices (each vertex is 3 floats)
        for (const vertex of triangle.vertices) {
          for (const v of vertex) {
            dataView.setFloat32(offset, v, true);
            offset += 4;
          }
        }
        // Write attribute byte count (2 bytes, usually zero)
        dataView.setUint16(offset, 0, true);
        offset += 2;
      }

      // Return the binary STL data as an ArrayBuffer
      return buffer;
    },

    uploadData2DTo3D: async function () {
      this.$store.commit("showLoader", true);
      this.isErrorMessage = true;
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          setTimeout(async () => {
            try {
              if (this.upperJawFile != null || this.lowerJawFile != null) {
                this.sumCredits =
                  this.upperJawFile && this.lowerJawFile
                    ? parseFloat(this.costsPerUpperJaw) +
                      parseFloat(this.costsPerLowerJaw)
                    : this.upperJawFile
                    ? parseFloat(this.costsPerUpperJaw)
                    : parseFloat(this.costsPerLowerJaw);

                if (this.sumCredits > parseFloat(this.credits)) {
                  this.$store.commit("showLoader", false);
                  this.showModal = true;
                } else {
                  this.isDisabled = true;
                  let uploadPromises = [];
                  if (this.upperJawFile != null) {
                    let exporter = new STLExporter();
                    //this.userModelUpper.rotation.y+=Math.PI;

                    // Update the matrixWorld to ensure it's current
                    this.userModelUpper.updateMatrixWorld();
                    this.userModelUpper.updateMatrix();

                    // Capture the registered matrixWorld
                    this.upperJawFileMatrixWorld["reg"] =
                      this.userModelUpper.matrixWorld.elements.slice();

                    this.upperJawFileRotation["reg"]["x"] =
                      this.userModelUpper.rotation.x;
                    this.upperJawFileRotation["reg"]["y"] =
                      this.userModelUpper.rotation.y;
                    this.upperJawFileRotation["reg"]["z"] =
                      this.userModelUpper.rotation.z;

                    this.upperJawFileRotation["reg"]["x"] =
                      this.userModelUpper.rotation.x;
                    this.upperJawFileRotation["reg"]["y"] =
                      this.userModelUpper.rotation.y;
                    this.upperJawFileRotation["reg"]["z"] =
                      this.userModelUpper.rotation.z;

                    this.upperJawFileTranslation["reg"]["x"] =
                      this.userModelUpper.position.x;
                    this.upperJawFileTranslation["reg"]["y"] =
                      this.userModelUpper.position.y;
                    this.upperJawFileTranslation["reg"]["z"] =
                      this.userModelUpper.position.z;

                    this.upperJawFileQuaternion["reg"]["x"] =
                      this.userModelUpper.quaternion.x;
                    this.upperJawFileQuaternion["reg"]["y"] =
                      this.userModelUpper.quaternion.y;
                    this.upperJawFileQuaternion["reg"]["z"] =
                      this.userModelUpper.quaternion.z;
                    this.upperJawFileQuaternion["reg"]["w"] =
                      this.userModelUpper.quaternion.w;

                    this.upperJawFileScale["reg"]["x"] =
                      this.userModelUpper.scale.x;
                    this.upperJawFileScale["reg"]["y"] =
                      this.userModelUpper.scale.y;
                    this.upperJawFileScale["reg"]["z"] =
                      this.userModelUpper.scale.z;

                    this.upperJawFileMatrix.reg =
                      this.userModelUpper.matrix.elements.slice();

                    let result = exporter.parse(this.userModelUpper);
                    const binaryData = this.convertASCIIToBinarySTL(result);
                    // Create a Blob object from the binary data
                    let blob = new Blob([binaryData], {
                      type: "application/octet-stream",
                    });
                    // Create a File object from the Blob
                    let fileUpper = new File([blob], this.upperJawFile.name, {
                      type: "application/octet-stream", // MIME type for STL
                      lastModified: new Date(2024, 3, 29, 16, 46, 36).getTime(), // Last modified date as timestamp
                    });
                    // Push the upper jaw upload promise to the array
                    uploadPromises.push(
                      FileService.uploadFile(
                        fileUpper,
                        { visibility: 0 },
                        0,
                        0.33
                      ).then(
                        function (response) {
                          this.upperJawFileInfo = response.data[0];
                        }.bind(this)
                      )
                    );
                  }
                  if (this.lowerJawFile != null) {
                    let exporter = new STLExporter();

                    // Update the matrixWorld to ensure it's current
                    this.userModel.updateMatrixWorld();
                    this.userModel.updateMatrix();

                    // Capture the registered matrixWorld
                    this.lowerJawFileMatrixWorld["reg"] =
                      this.userModel.matrixWorld.elements.slice();

                    this.lowerJawFileRotation["reg"]["x"] =
                      this.userModel.rotation.x;
                    this.lowerJawFileRotation["reg"]["y"] =
                      this.userModel.rotation.y;
                    this.lowerJawFileRotation["reg"]["z"] =
                      this.userModel.rotation.z;

                    this.lowerJawFileTranslation["reg"]["x"] =
                      this.userModel.position.x;
                    this.lowerJawFileTranslation["reg"]["y"] =
                      this.userModel.position.y;
                    this.lowerJawFileTranslation["reg"]["z"] =
                      this.userModel.position.z;

                    this.lowerJawFileQuaternion["reg"]["x"] =
                      this.userModel.quaternion.x;
                    this.lowerJawFileQuaternion["reg"]["y"] =
                      this.userModel.quaternion.y;
                    this.lowerJawFileQuaternion["reg"]["z"] =
                      this.userModel.quaternion.z;
                    this.lowerJawFileQuaternion["reg"]["w"] =
                      this.userModel.quaternion.w;

                    this.lowerJawFileScale["reg"]["x"] = this.userModel.scale.x;
                    this.lowerJawFileScale["reg"]["y"] = this.userModel.scale.y;
                    this.lowerJawFileScale["reg"]["z"] = this.userModel.scale.z;

                    this.lowerJawFileMatrix.reg =
                      this.userModel.matrix.elements.slice();

                    let result = exporter.parse(this.userModel);
                    const binaryData = this.convertASCIIToBinarySTL(result);
                    // Create a Blob object from the binary data
                    let blob = new Blob([binaryData], {
                      type: "application/octet-stream",
                    });
                    // Create a File object from the Blob
                    let file = new File([blob], this.lowerJawFile.name, {
                      type: "application/octet-stream", // MIME type for STL
                      lastModified: new Date(2024, 3, 29, 16, 46, 36).getTime(), // Last modified date as timestamp
                    });
                    // Push the lower jaw upload promise to the array
                    uploadPromises.push(
                      FileService.uploadFile(
                        file,
                        { visibility: 0 },
                        0.33,
                        0.33
                      ).then(
                        function (response) {
                          this.lowerJawFileInfo = response.data[0];
                        }.bind(this)
                      )
                    );
                  }
                  // Wait for all uploads to complete
                  Promise.all(uploadPromises).then(async () => {
                    await this.createCase();
                    this.$store.commit("showLoader", false);
                  });
                }
              }
            } catch (e) {
              console.error(e);
              this.$store.commit("showLoader", false);
            }
          }, 0);
        } else {
          this.$store.commit("showLoader", false);
        }
      });
    },

    async createCase() {
      var data = {
        files: {
          upper: this.upperJawFileInfo?.id ?? null,
          lower: this.lowerJawFileInfo?.id ?? null,
          upper_rotation: this.upperJawFileRotation,
          lower_rotation: this.lowerJawFileRotation,
          upper_translation: this.upperJawFileTranslation,
          lower_translation: this.lowerJawFileTranslation,
          upper_matrixWorld: this.upperJawFileMatrixWorld,
          lower_matrixWorld: this.lowerJawFileMatrixWorld,
          upper_quaternion: this.upperJawFileQuaternion,
          lower_quaternion: this.lowerJawFileQuaternion,
          upper_scale: this.upperJawFileScale,
          lower_scale: this.lowerJawFileScale,
          upper_matrixLocal: this.upperJawFileMatrix,
          lower_matrixLocal: this.lowerJawFileMatrix,
        },
        extensions: [{ id: "opg-to-3d" }],
        details: this.caseDetails,
        name: this.caseName,
      };

      this.$store.commit("showLoader", true);
      await this.$store
        .dispatch("caseLists/create", data)
        .then(
          async function (response) {
            await this.$store.dispatch("customers/getCredits");
            this.$store.commit("showLoader", false);
            this.$router.push({ path: "/my-cases/active" });
          }.bind(this)
        )
        .finally(() => {
          this.termsAndConditions = false;
          this.upperJawFileInfo = null;
          this.lowerJawFileInfo = null;
          this.$store.commit("showLoader", false);
        });
    },

    uploadDone: function () {
      NProgress.done();
      this.isDisabled = false;
      this.loadingDone = true;
    },
    previewJaw(jawType, event) {
      const isUpper = jawType === "upper";
      const file = event.target.files[0];
      if (!file) return; // No file selected

      const modelKey = isUpper ? "userModelUpper" : "userModel";
      const groupKey = isUpper ? "userGroupUpper" : "userGroup";
      const fileRotationKey = isUpper
        ? "upperJawFileRotation"
        : "lowerJawFileRotation";
      const fileTranslationKey = isUpper
        ? "upperJawFileTranslation"
        : "lowerJawFileTranslation";
      const fileMatrixWorldKey = isUpper
        ? "upperJawFileMatrixWorld"
        : "lowerJawFileMatrixWorld";
      const fileQuaternionKey = isUpper
        ? "upperJawFileQuaternion"
        : "lowerJawFileQuaternion";
      const fileScaleKey = isUpper ? "upperJawFileScale" : "lowerJawFileScale";
      const fileMatrixKey = isUpper
        ? "upperJawFileMatrix"
        : "lowerJawFileMatrix";
      this[isUpper ? "isSelectedJaw" : "isSelectedJaw"] = jawType;
      this[isUpper ? "upperJawFile" : "lowerJawFile"] = file;

      let reader = new FileReader();
      const extension = file.name.split(".").pop().toLowerCase();

      reader.addEventListener("load", (event) => {
        const contents = event.target.result;

        if (this[modelKey]) {
          this[groupKey].remove(this[modelKey]);
        }

        let loader;
        switch (extension) {
          case "stl":
            loader = new STLLoader();
            this[modelKey] = new THREE.Mesh(
              loader.parse(contents),
              new THREE.MeshNormalMaterial({
                side: THREE.DoubleSide,
              })
            );
            break;
          case "obj":
            loader = new OBJLoader();
            this[modelKey] = loader.parse(contents);
            this.centerObject(this[modelKey]);
            break;
          case "ply":
            loader = new PLYLoader();
            this[modelKey] = new THREE.Mesh(
              loader.parse(contents),
              new THREE.MeshNormalMaterial({
                side: THREE.DoubleSide,
              })
            );
            break;
          default:
            console.log("Unsupported file format");
            return;
        }

        // Add the model to the appropriate group
        this[groupKey].add(this[modelKey]);

        // Add Transform Controls
        this.addTransformControls(this[modelKey], isUpper);

        // Update the matrixWorld to ensure it's current
        this[modelKey].updateMatrixWorld();
        this[modelKey].updateMatrix();

        // Capture the original matrixWorld
        this[fileMatrixWorldKey]["orig"] =
          this[modelKey].matrixWorld.elements.slice();

        this[fileMatrixKey] = {
          orig: this[modelKey].matrix.elements.slice(),
        };

        // Capture original scale
        this[fileScaleKey]["orig"]["x"] = this[modelKey].scale.x;
        this[fileScaleKey]["orig"]["y"] = this[modelKey].scale.y;
        this[fileScaleKey]["orig"]["z"] = this[modelKey].scale.z;

        this[fileQuaternionKey]["orig"]["x"] = this[modelKey].quaternion.x;
        this[fileQuaternionKey]["orig"]["y"] = this[modelKey].quaternion.y;
        this[fileQuaternionKey]["orig"]["z"] = this[modelKey].quaternion.z;
        this[fileQuaternionKey]["orig"]["w"] = this[modelKey].quaternion.w;

        this[fileRotationKey]["orig"]["x"] = this[modelKey].rotation.x;
        this[fileRotationKey]["orig"]["y"] = this[modelKey].rotation.y;
        this[fileRotationKey]["orig"]["z"] = this[modelKey].rotation.z;

        this[fileTranslationKey]["orig"]["x"] = this[modelKey].position.x;
        this[fileTranslationKey]["orig"]["y"] = this[modelKey].position.y;
        this[fileTranslationKey]["orig"]["z"] = this[modelKey].position.z;
      });

      if (extension === "stl" || extension === "ply") {
        reader.readAsArrayBuffer(file);
      } else if (extension === "obj") {
        reader.readAsText(file);
      } else {
        console.log("Unsupported file format");
      }
    },

    addTransformControls(object, isUpper) {
      const scene = isUpper ? this.sceneUpper : this.scene;
      const camera = isUpper ? this.cameraUpper : this.camera;
      const renderer = isUpper ? this.rendererUpper : this.renderer;
      const controls = isUpper ? this.controlsUpper : this.controls;

      // Check and dispose of any existing transformControl
      if (isUpper && this.transformControlUpper) {
        scene.remove(this.transformControlUpper);
        this.transformControlUpper.dispose();
      } else if (!isUpper && this.transformControl) {
        scene.remove(this.transformControl);
        this.transformControl.dispose();
      }

      // Initialize a new TransformControls instance
      const newTransformControl = new TransformControls(
        camera,
        renderer.domElement
      );
      newTransformControl.attach(object);
      scene.add(newTransformControl);

      // Store the new TransformControls instance in the correct property
      if (isUpper) {
        this.transformControlUpper = newTransformControl;
      } else {
        this.transformControl = newTransformControl;
      }

      newTransformControl.addEventListener("change", () => {
        isUpper ? this.renderUpper() : this.render();
      });
      newTransformControl.addEventListener("dragging-changed", (event) => {
        controls.enabled = !event.value;
      });

      // Set initial mode to translate
      newTransformControl.setMode("translate");
    },

    centerObject(object) {
      // Compute bounding box
      const box = new THREE.Box3().setFromObject(object);
      // Compute center
      const center = box.getCenter(new THREE.Vector3());
      // Reposition object so that its center is at the origin
      object.position.sub(center);
    },
    deleteUpperJawFile() {
      this.upperJawFile = null;
      this.userGroupUpper.remove(this.userModelUpper);
    },

    deleteLowerJawFile() {
      this.lowerJawFile = null;
      this.userGroup.remove(this.userModel);
    },
    openUpperJawFilePicker() {
      this.$refs.fileUpperJawInput.click();
    },
    openLowerJawFilePicker() {
      this.$refs.fileLowerJawInput.click();
    },
    /**
     * @param {KeyboardEvent} event
     */
    handleKeyDown(event) {
      let ctrlKey = navigator.userAgent.includes("Mac") ? "Meta" : "Control";
      if (event.key === ctrlKey) {
        if (this.transformControlUpper) this.transformControlUpper.setMode("rotate");
        if (this.transformControl) this.transformControl.setMode("rotate");
      }
    },
    /**
     * @param {KeyboardEvent} event
     */
    handleKeyUp(event) {
      let ctrlKey = navigator.userAgent.includes("Mac") ? "Meta" : "Control";
      if (event.key === ctrlKey) {
        if (this.transformControlUpper) this.transformControlUpper.setMode("translate");
        if (this.transformControl) this.transformControl.setMode("translate");
      }
    },
    onDragOver() {
      this.isDragOver = true;
    },
    onDragLeave() {
      this.isDragOver = false;
    },
    onDrop(event, jawType) {
      this.isDragOver = false;
      const droppedFiles = event.dataTransfer.files;
      if (droppedFiles && droppedFiles.length > 0) {
        // Dynamically call the appropriate preview function based on jawType
        if (jawType === "upper") {
          this.previewJaw("upper", {
            target: { files: droppedFiles },
          });
        } else if (jawType === "lower") {
          this.previewJaw("lower", {
            target: { files: droppedFiles },
          });
        }
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    document.body.classList.add("cookie-layout");
    next();
  },
  beforeRouteLeave(to, from, next) {
    document.body.classList.remove("cookie-layout");
    next();
  },
};
</script>

<style>
.lower-card,
.upper-card {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.lower-card.active {
  z-index: 1;
}

.upper-canvas {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  left: 0;
  top: 0;
}
</style>
